import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useMutation} from "@tanstack/react-query";
import SignInAction from "./SignInAction";
import {toast} from "react-toastify";
import {Backdrop, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useState} from "react";


export default function SignIn(options) {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const {mutate, isLoading} = useMutation(SignInAction, {
        onSuccess : data => {
            const message = "Success";

            if(data?.accessToken) {
                localStorage.setItem('accessToken', data.accessToken);
                toast(message)
                navigate('/dashboard')
            }

        },
        onError   : () => {
            toast("There was an error")
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        mutate({
            username : data.get('email'),
            password : data.get('password'),
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Backdrop
                sx={{color : '#fff', zIndex : (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box
                sx={{
                    marginTop     : 8,
                    display       : 'flex',
                    flexDirection : 'column',
                    alignItems    : 'center',
                }}
            >
                <Avatar sx={{m : 1, bgcolor : 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt : 1}}>
                    <TextField
                        value={userName}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e)=>setUserName(e.target.value)}
                    />
                    <TextField
                        value={password}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt : 3, mb : 2}}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}