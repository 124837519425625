import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems} from './listItems';
import {AppBar, Drawer} from './Drawer';
import Button from "@mui/material/Button";
import {Outlet, useNavigate} from "react-router-dom";
import logo from '../../logo.png';

function DashboardContent() {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{display : 'flex'}}>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr : '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight : '36px',
                            ...(open && {display : 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow : 1}}
                    >
                        Dashboard
                    </Typography>
                    <Button variant={'outlined'} color="inherit" onClick={() => {
                        localStorage.removeItem('accessToken');
                        navigate('/sign-in')
                    }}>
                        Sign Out
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display        : 'flex',
                        alignItems     : 'center',
                        justifyContent : 'flex-end',
                        px             : [1],
                    }}
                >
                    <img src={logo} alt="logo" name="logo" />
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List component="nav">
                    {mainListItems}
                    <Divider sx={{my : 1}}/>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor : (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow        : 1,
                    height          : '100vh',
                    overflow        : 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="xl" sx={{mt : 4, mb : 4}}>
                    <Paper
                        sx={{p : 2}}
                    >
                        <Outlet/>
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
}

export default function Dashboard() {
    return <DashboardContent/>;
}