import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Link } from "react-router-dom";

export const mainListItems = (
    <>
        <ListItemButton component={Link} to={'subscriptions'}>
            <ListItemIcon>
                <CurrencyExchangeIcon/>
            </ListItemIcon>
            <ListItemText primary="Subscriptions"/>
        </ListItemButton>
        <ListItemButton  component={Link} to={'transactions'}>
            <ListItemIcon>
                <AccountBalanceIcon/>
            </ListItemIcon>
            <ListItemText primary="Transactions"/>
        </ListItemButton>
    </>
);
