import {Navigate, Outlet} from 'react-router-dom'

const PrivateRoutes = () => {
    const isLoggedIn = Boolean(localStorage.getItem("accessToken"))

    return (
        isLoggedIn ? <Outlet/> : <Navigate to='/sign-in'/>
    )
}

export default PrivateRoutes;