import React, {useState} from 'react';
import {ExportSubscriptions, getSubscriptions} from "./SubscriptionActions";
import {useQuery} from "@tanstack/react-query";
import {Backdrop, CircularProgress} from "@mui/material";
import {DateFilter, LAST_TRANSFER_STATUS_OPTIONS, NumberRangeFilter, SelectColumnFilter, STATUS_OPTIONS} from "../Table/Filters";
import {format} from 'date-fns';
import {toast} from "react-toastify";
import MyTable from "../Table/MyTable";
import {Link, useLocation} from "react-router-dom";
import {queryParamsToFilters} from "../utils";
import queryString from "query-string";

const columns = [
    {
        accessor : 'id',
        Header   : 'ID',
        Filter   : <span/>
    },
    {
        accessor : 'firstName',
        Header   : 'First name',
    },
    {
        accessor : 'lastName',
        Header   : 'Last name',


    },
    {
        accessor : 'email',
        Header   : 'Email',

    },
    {
        accessor : 'cardLastDigits',
        Header   : 'Card last 4 Digits',
    },
    {
        accessor : 'amount',
        Header   : 'Sum',
        Filter   : NumberRangeFilter,
    },
    {
        accessor : 'status',
        Header   : 'Status',
        Filter   : SelectColumnFilter,
        options  : STATUS_OPTIONS
    },
    {
        accessor : 'lastTransferStatus',
        Header   : 'Last transfer',
        Filter   : SelectColumnFilter,
        options  : LAST_TRANSFER_STATUS_OPTIONS
    },
    {
        accessor : 'startDate',
        Header   : 'Subscribed',
        Cell     : props => <span>{format(new Date(props.value), 'MMMM dd, yyyy h:mm a')}</span>,
        Filter   : DateFilter

    },
    {
        accessor : 'endDate',
        Header   : 'Canceled',
        Cell     : props => <span> {props.value ? format(new Date(props.value), 'MMMM dd, yyyy h:mm a') : "-"}</span>,
        Filter   : DateFilter
    },
    {
        accessor : 'viewTransactions',
        Header   : 'View Transactions',
        Cell     : props => {
            if(props?.row?.values?.email) {
                return <Link target='_blank' to={`/dashboard/transactions?email=${props.row.values.email}`}>{props.row.values.email}</Link>
            }
            return <div>N/A</div>
        }
    },
    {
        accessor : 'subscriptionPageId',
        Header   : 'Subscription page',
        Cell     : props => <a target="_blank" rel="noreferrer" href={`https://donate.gituzh.am/recurring?id=${props.value}`}>{props.value}</a>,
        width    : 500
    },
    {
        accessor : 'notes',
        Header   : 'Notes',
        Filter   : <span/>
    },
];

const Subscriptions = () => {
    const location = useLocation();
    const queryParams = queryParamsToFilters(queryString.parse(location.search));
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0)
    const [filters, setFilters] = useState(queryParams);

    const {
        isLoading,
        isError,
        error,
        data : apiData,
        isFetching,
    } = useQuery({
            queryKey  : ['subscriptions', filters, pageIndex, pageSize],
            queryFn   : () => getSubscriptions(filters, pageIndex, pageSize),
            onSettled : (response) => {
                if(response.statusCode === 401) {
                    localStorage.removeItem('accessToken')
                    window.location.reload();
                } else if(response.statusCode === 400 && response.error) {
                    toast(response.error)
                }
            },
            onError   : () => {
                toast.error("Something went wrong.")
            }
        }
    )

    return (
        <div>
            <Backdrop
                sx={{color : '#fff', zIndex : (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isFetching}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {isError ? (
                <div>Error: {error.message}</div>
            ) : (
                <div>
                    <MyTable
                        key={'subscriptions'}
                        onFilterChange={(value) => {
                            setFilters(value)
                            setPageIndex(0)
                        }}
                        filters={filters}
                        columns={columns}
                        data={apiData?.items || []}
                        pageCount={Math.ceil((apiData?.count || 0) / pageSize)}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        onPageChange={(page, limit) => {
                            setPageSize(limit);
                            setPageIndex(page);
                        }}
                        onExport={() => {
                            return ExportSubscriptions(filters, pageIndex, pageSize)
                        }}
                    />

                </div>
            )}
        </div>
    )
};

export default Subscriptions;

