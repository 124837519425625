import React from 'react';
import './pagination.css';
const Pagination = ({previousPage, canPreviousPage, nextPage, canNextPage, pageIndex, pageOptions, gotoPage, pageSize, setPageSize}) => {

    return (
        <div className="pagination">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </button>
            <div>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </div>
            {/*<div>*/}
            {/*    | Go to page:{' '}*/}
            {/*    <input*/}
            {/*        type="number"*/}
            {/*        defaultValue={pageIndex + 1}*/}
            {/*        onChange={(e) => {*/}
            {/*            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;*/}
            {/*            gotoPage(pageNumber);*/}
            {/*        }}*/}
            {/*        style={{width : '50px'}}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    | Page size:{' '}*/}
            {/*    <select*/}
            {/*        value={pageSize}*/}
            {/*        onChange={(e) => {*/}
            {/*            setPageSize(Number(e.target.value));*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {[10, 20, 30, 40, 50].map((pageSize) => (*/}
            {/*            <option key={pageSize} value={pageSize}>*/}
            {/*                {pageSize}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}
        </div>
    );
};

export default Pagination;
