import './App.css';
import SignIn from "./components/SignIn/SignIn";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
// app.js
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';


import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Dashboard from "./components/Dashboard/Dashboard";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Transactions from "./components/Transactions/Transactions";
import Subscriptions from "./components/Subscriptions/Subscriptions";


// Initialize the client
const queryClient = new QueryClient({
    defaultOptions : {
        queries : {
            refetchOnWindowFocus : false, // default: true
        },
    },
});
const theme = createTheme();


function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <ToastContainer theme={'dark'}/>
                <Router>
                    <Routes>
                        <Route element={<PrivateRoutes/>}>
                            <Route path='/' element={<Navigate to={'dashboard'}/>}/>
                            <Route path='dashboard' element={<Dashboard/>}>
                                <Route index element={<Subscriptions/>}/>
                                <Route path="subscriptions" element={<Subscriptions/>}/>
                                <Route path="transactions" element={<Transactions/>}/>
                            </Route>
                        </Route>
                        <Route path='/sign-in' element={<SignIn/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </Router>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
