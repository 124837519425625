import queryString from "query-string";

export const queryParamsToFilters = (params = {}) => {
    return Object.keys(params).reduce((accum, aggr) => {

        if(aggr === 'from' || aggr === 'to') {
            const alreadyExits = accum.find((info) => info.id === "createdAt")
            const dateFilter = alreadyExits ? alreadyExits : {
                id    : 'createdAt',
                value : {}
            }

            if(aggr === "from") {
                dateFilter.value.from = params[aggr];
            }
            if(aggr === "to") {
                dateFilter.value.to = params[aggr];
            }

            if(!alreadyExits) {
                accum.push(dateFilter);
            }

        }

        if(aggr === 'startFrom' || aggr === 'startTo') {
            const alreadyExits = accum.find((info) => info.id === "startDate")
            const dateFilter = alreadyExits ? alreadyExits : {
                id    : 'startDate',
                value : {}
            }

            if(aggr === "startFrom") {
                dateFilter.value.from = params[aggr];
            }
            if(aggr === "startTo") {
                dateFilter.value.to = params[aggr];
            }

            if(!alreadyExits) {
                accum.push(dateFilter);
            }
        }

        if(aggr === 'endFrom' || aggr === 'endTo') {
            const alreadyExits = accum.find((info) => info.id === "endDate")
            const dateFilter = alreadyExits ? alreadyExits : {
                id    : 'endDate',
                value : {}
            }

            if(aggr === "endFrom") {
                dateFilter.value.from = params[aggr];
            }
            if(aggr === "endTo") {
                dateFilter.value.to = params[aggr];
            }

            if(!alreadyExits) {
                accum.push(dateFilter);
            }
        }

        if(aggr === 'sumMin' || aggr === 'sumMax') {

            const alreadyExits = accum.find((info) => info.id === "amount")

            const numberFilter = alreadyExits ? alreadyExits : {
                id    : 'amount',
                value : []
            }

            if(aggr === "sumMin") {
                numberFilter.value[0] = params[aggr]
            }
            if(aggr === "sumMax") {
                numberFilter.value[1] = params[aggr]
            }

            if(!alreadyExits) {
                accum.push(numberFilter)
            }


        } else {
            accum.push({
                id    : aggr,
                value : params[aggr]
            });
        }


        return accum
    }, []);
}


export const filtersToQueryParams = (filters = []) => {
    return filters.reduce((accum, aggr) => {

        const id = aggr.id
        const value = aggr.value;

        if(id === "amount") {
            if(aggr.value[0]) accum.sumMin = aggr.value[0]
            if(aggr.value[1]) accum.sumMax = aggr.value[1]
        } else if(id === 'createdAt') {
            if(aggr.value.from) {
                accum.from = aggr.value.from
            }
            if(aggr.value.to) {
                accum.to = aggr.value.to
            }
        }
        else if(id === "startDate") {
            if(aggr.value.from) {
                accum.startFrom = aggr.value.from
            }
            if(aggr.value.to) {
                accum.startTo = aggr.value.to
            }
        }
        else if(id === "endDate") {
            if(aggr.value.from) {
                accum.endFrom = aggr.value.from
            }
            if(aggr.value.to) {
                accum.endTo = aggr.value.to
            }
        }
        else if(id && value) {
            accum[id] = value
        }


        return accum
    }, {});
}


export const getProcessedUrl = (url, {filters, pageIndex, pageSize, availableFilters}) => {
    const parsedUrl = queryString.parseUrl(url);

    filters.forEach((filterInfo) => {
        const isSupported = availableFilters.includes(filterInfo.id)
        if(isSupported) {
            parsedUrl.query[filterInfo.id] = filterInfo.value
        }

        if(filterInfo.id === 'amount' && Array.isArray(filterInfo.value)) {
            if(filterInfo.value[0]) {
                parsedUrl.query.sumMin = filterInfo.value[0]
            }
            if(filterInfo.value[1]) {
                parsedUrl.query.sumMax = filterInfo.value[1]
            }
        }

        if(filterInfo.id === 'createdAt' && Object.keys(filterInfo.value).length) {
            if(filterInfo.value.from) {
                parsedUrl.query.from = filterInfo.value.from
            }
            if(filterInfo.value.to) {
                parsedUrl.query.to = filterInfo.value.to
            }
        }


        if(filterInfo.id === 'startDate' && Object.keys(filterInfo.value).length) {
            if(filterInfo.value.from) {
                parsedUrl.query.startFrom = filterInfo.value.from
            }
            if(filterInfo.value.to) {
                parsedUrl.query.startTo = filterInfo.value.to
            }
        }

        if(filterInfo.id === 'endDate' && Object.keys(filterInfo.value).length) {
            if(filterInfo.value.from) {
                parsedUrl.query.endFrom = filterInfo.value.from
            }
            if(filterInfo.value.to) {
                parsedUrl.query.endTo = filterInfo.value.to
            }
        }




        if(filterInfo.id === "bankMessage") {
            parsedUrl.query['message'] = filterInfo.value
        }
    })

    parsedUrl.query.offset = pageIndex * pageSize;
    parsedUrl.query.limit = pageSize;


    return queryString.stringifyUrl(parsedUrl);
}

export const API_URL = "https://gituzh-donations.herokuapp.com"