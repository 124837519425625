import React, {useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {ExportTransactions, getTransactions} from "./TransactionActions";
import {Backdrop, CircularProgress} from "@mui/material";
import MyTable from "../Table/MyTable";
import {DateFilter, LAST_TRANSFER_STATUS_OPTIONS, NumberRangeFilter, SelectColumnFilter} from "../Table/Filters";
import {format} from "date-fns";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {queryParamsToFilters} from "../utils";
import {toast} from "react-toastify";


const columns = [
    {
        accessor : 'id',
        Header   : 'ID',
        Filter   : <span/>
    },
    {
        accessor : 'firstName',
        Header   : 'First name',
    },
    {
        accessor : 'lastName',
        Header   : 'Last name',
    },
    {
        accessor : 'email',
        Header   : 'Email',
    },
    {
        accessor : 'cardLastDigits',
        Header   : 'Card last 4 Digits',
        type     : 'number',
    },
    {
        accessor : 'amount',
        Header   : 'Sum',
        Filter   : NumberRangeFilter,
    },
    {
        accessor : 'status',
        Header   : 'Status',
        Filter   : SelectColumnFilter,
        options  : LAST_TRANSFER_STATUS_OPTIONS
    },
    {
        accessor : 'createdAt',
        Header   : 'Date',
        Cell     : props => <span>{format(new Date(props.value), 'MMMM dd, yyyy h:mm a')}</span>,
        Filter   : DateFilter
    },
    {
        accessor : 'bankMessage',
        Header   : 'Message - Bank API',
    }
];


const Transactions = () => {
    const location = useLocation();
    const queryParams = queryParamsToFilters(queryString.parse(location.search));
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [filters, setFilters] = useState(queryParams);
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching
    } = useQuery({
            queryKey         : ['transactions', filters, pageIndex, pageSize],
            queryFn          : () => getTransactions(filters, pageIndex, pageSize),
            keepPreviousData : true,
            onSettled        : (response) => {
                if(response.statusCode === 401) {
                    localStorage.removeItem('accessToken')
                    window.location.reload();
                } else if(response.statusCode === 400 && response.error) {
                    toast(response.error)
                }
            },
            onError          : () => {
                toast.error("Something went wrong.")
            }
        }
    )

    return (
        <div>
            <Backdrop
                sx={{color : '#fff', zIndex : (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || isFetching}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {isError ? (
                <div>Error: {error.message}</div>
            ) : (
                <div>
                    <MyTable
                        key={'transactions'}
                        onFilterChange={(value) => {
                            setFilters(value)
                            setPageIndex(0)
                        }}
                        filters={filters}
                        columns={columns}
                        data={data?.items || []}
                        pageCount={Math.ceil((data?.count || 0) / pageSize)}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        onPageChange={(page, limit) => {
                            setPageSize(limit);
                            setPageIndex(page);
                        }}
                        onExport={() => {
                            return ExportTransactions(filters, pageIndex, pageSize)
                        }}/>
                </div>
            )}
        </div>
    );
};

export default Transactions;
