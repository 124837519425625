import React, {useState} from "react";

export function ColumnFilter({column}) {
    const {filterValue, setFilter} = column;

    return (<input
        className={"filter-input"}
        type="text"
        value={filterValue || ""}
        onChange={(e) => {
            setFilter(e.target.value);
        }}
        placeholder={`Filter ${column.Header}`}
    />);
}

export const STATUS_OPTIONS = [
    "Active",
    "Canceled"
]

export const LAST_TRANSFER_STATUS_OPTIONS = [
    "Unsuccessful",
    "Successful"
]

export function SelectColumnFilter({column : {options = [], filterValue, setFilter, preFilteredRows = [], id},}) {
    return (<select
        className={"filter-input"}
        value={filterValue}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
    >
        <option value="">All</option>
        {options.map((option, i) => (<option key={i} value={option}>
            {option}
        </option>))}
    </select>)
}

export function NumberRangeFilter({column : {filterValue = [], setFilter}}) {
    return (<div
        style={{
            display : 'flex', alignItems : "baseline"
        }}
    >
        <input
            className={"filter-input"}
            value={filterValue[0] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value
                setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
            }}
            placeholder={`Min`}
            style={{marginRight : '0.5rem'}}
        />
        to
        <input
            className={"filter-input"}
            value={filterValue[1] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value
                setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
            }}
            placeholder={`Max`}
            style={{marginLeft : '0.5rem'}}
        />
    </div>)
}


export function DateFilter({column : {filterValue = {}, setFilter}}) {
    const [startDate, setStartDate] = useState(filterValue.from || '')
    const [endDate, setEndDate] = useState(filterValue.to || '')

    function onChangeStartDate(e) {
        const date = e.target.value
        setStartDate(date)
        setFilter((old = {}) => ({...old, from : date}))
    }

    function onChangeEndDate(e) {
        const date = e.target.value
        setEndDate(date)
        setFilter((old = {}) => ({...old, to : date}))
    }

    return (<div style={{
        display : 'flex', alignItems : "baseline"
    }}>
        <input style={{marginRight : '0.5rem'}} onChange={onChangeStartDate} className={"filter-input"} type="datetime-local" name="meeting-time" value={startDate}/>
        <span> to </span>
        <input style={{marginLeft : '0.5rem'}} onChange={onChangeEndDate} className={"filter-input"} type="datetime-local" name="meeting-time" value={endDate}/>
    </div>)
}