import React from 'react';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div style={{display : "grid", alignItems : 'center', justifyContent : 'center'}}>
            <div>
                <h2>404 Page not found</h2>
            </div>
            <Button variant='contained' onClick={() => {
                navigate('dashboard')
            }}>Go To Dashboard</Button>
        </div>
    );
};

export default PageNotFound;
