import React, {useEffect, useMemo, useState} from "react";
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import "./style.css";
import {ColumnFilter} from "./Filters";
import Pagination from './Pagination';
import {filtersToQueryParams} from "../utils";
import {useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearIcon from '@mui/icons-material/Clear';
import {Backdrop, CircularProgress} from "@mui/material";

function MyTable({
                     data,
                     columns,
                     onFilterChange,
                     filters   : initialFilters = [],
                     pageCount : controlledPageCount,
                     onPageChange,
                     pageIndex : propsPageIndex,
                     pageSize  : propsPageSize,
                     onExport = () => Promise.resolve()
                 }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const defaultColumn = useMemo(
        () => ({
            Filter : ColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setAllFilters
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState     : {pageIndex : propsPageIndex, pageSize : propsPageSize, filters : initialFilters},
            manualFilters    : true,
            manualPagination : true,
            pageCount        : controlledPageCount
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const {pageIndex, pageSize, filters} = state

    const [isLoading, setIsLoading] = useState(false);

    const handleFilterChange = useAsyncDebounce((value) => {
        setIsLoading(true)
        onFilterChange(value)
        const valuesObj = filtersToQueryParams(value)

        const newSearchParams = new URLSearchParams()

        Object.keys(valuesObj).forEach((key) => {
            newSearchParams.set(key, valuesObj[key])
        })

        setSearchParams(newSearchParams.toString())
        setIsLoading(false)
    }, 2000);

    useEffect(() => {
        if(JSON.stringify(filters) !== JSON.stringify(initialFilters)) {
            handleFilterChange(filters)
        }

    }, [JSON.stringify(filters)])

    const paginationProps = {
        previousPage, canPreviousPage, nextPage, canNextPage, pageIndex, pageOptions, gotoPage, pageSize, setPageSize
    }

    useMemo(() => {
        onPageChange && onPageChange(pageIndex, pageSize);
    }, [onPageChange, pageIndex, pageSize]);

    return (
        <>
            <Backdrop
                sx={{color : '#fff', zIndex : (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div style={{width : '100%', overflowX : 'auto'}}>
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th key={column.id}>
                                        <div>{column.render("Header")}</div>
                                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div style={{display : 'flex', flexDirection : "row", gap : 10}}>
                <Pagination {...paginationProps}/>

                <Button
                    variant={'outlined'}
                    style={{marginTop : '1rem'}}
                    endIcon={<FileDownloadIcon/>}
                    onClick={() =>
                        onExport()
                            .then(blob => {
                                if(blob) {
                                    const url = URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.href = url;
                                    a.download = 'export.csv';
                                    document.body.appendChild(a);
                                    a.click();
                                    document.body.removeChild(a);
                                }
                            })
                    }
                >
                    Export CSV
                </Button>

                <Button
                    variant={'outlined'}
                    disabled={!filters.length}
                    style={{marginTop : '1rem'}}
                    onClick={() => setAllFilters([])}
                    endIcon={<ClearIcon/>}
                >
                    Clear Filters
                </Button>
            </div>
        </>
    )
}

export default MyTable;
