import {API_URL} from "../utils";

const SignInAction = async ({username, password}) => {
    const requestOptions = {
        method  : 'POST',
        headers : {'Content-Type' : 'application/json'},
        body    : JSON.stringify({username, password})
    };

    const respJson = await fetch(`${API_URL}/auth/login`, requestOptions)

    return await respJson.json();
}
export default SignInAction;