import {API_URL, getProcessedUrl} from "../utils";

const availableFilters = ['limit', 'offset', 'from', 'to', 'status', 'firstName', 'lastName', 'email', 'cardLastDigits', 'sumMin', 'sumMax', "message"]
export const getTransactions = async (filters = [], pageIndex = 0, pageSize = 10) => {
    const url = `${API_URL}/admin/transaction`;

    const requestOptions = {
        method  : 'GET',
        headers : {
            'Content-Type'  : 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("accessToken")}`
        }
    };

    const processedURL = getProcessedUrl(url, {filters, pageIndex, pageSize, availableFilters})

    const respJson = await fetch(processedURL, requestOptions)

    return await respJson.json();
}


export const ExportTransactions = async (filters = [], pageIndex = 0, pageSize = 10) => {
    const url = `${API_URL}/admin/export/transaction`;

    const requestOptions = {
        method  : 'GET',
        headers : {
            'Content-Type'  : 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("accessToken")}`
        }
    };

    const processedURL = getProcessedUrl(url, {filters, pageIndex, pageSize, availableFilters})

    const respJson = await fetch(processedURL, requestOptions)

    return await respJson.blob();
}
