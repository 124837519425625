import {API_URL, getProcessedUrl} from "../utils";

const availableFilters = ['limit', 'offset', 'from', 'to', 'status', 'lastTransferStatus', 'firstName', 'lastName', 'email', 'cardLastDigits', 'sumMin', 'sumMax']
export const getSubscriptions = async (filters = [], pageIndex = 0, pageSize = 10) => {
    const url = `${API_URL}/admin/subscription`;

    const requestOptions = {
        method  : 'GET',
        headers : {
            'Content-Type'  : 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("accessToken")}`
        }
    };

    const processedURL = getProcessedUrl(url, {filters, pageIndex, pageSize, availableFilters})

    const respJson = await fetch(processedURL, requestOptions)

    return await respJson.json();
}


export const ExportSubscriptions = async (filters = [], pageIndex = 0, pageSize = 10) => {
    const url = `${API_URL}/admin/export/subscription`;

    const requestOptions = {
        method  : 'GET',
        headers : {
            'Content-Type'  : 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("accessToken")}`
        }
    };

    const processedURL = getProcessedUrl(url, {filters, pageIndex, pageSize, availableFilters})

    const respJson = await fetch(processedURL, requestOptions)

    return await respJson.blob();
}